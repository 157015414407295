@import "./variables";

body {
  color: $black;
  @include font-regular;
  font-size: 16px;
  font-smooth: always;
}

h1,
h2 {
  @include font-heavy;
}

h1 {
  font-size: 2.5em;
  line-height: 1.2;
}

h2 {
  font-size: 1.8em;
  line-height: 1.1;
}

p {
  @include font-regular;
  font-size: 16px;
}

em,
b {
  @include font-bold;
}

button,
.button {
  @include font-heavy;
}

a {
  color: #000000;
}
