@font-face {
  font-family: "96SansCondensed";
  src: local("96SansCondensed"),
    url("../../public/fonts/96Sans-Condensed.otf") format("opentype");
}

@font-face {
  font-family: "96SansBoldCondensed";
  src: local("96SansBoldCondensed"),
    url("../../public/fonts/96Sans-BoldCondensed.otf") format("opentype");
}

@font-face {
  font-family: "96SansHeavy";
  src: local("96SansHeavy"),
    url("../../public/fonts/96Sans-Heavy.otf") format("opentype");
}
