// Breakpoints
$xs: 0;
$sm: 576px;
$md: 768px;
$lg: 992px;
$xl: 1200px;
$xxl: 1300px;

// Colors
$black: #000000;
$white: #ffffff;
$grey: #5f5e69;
$blue-smoke: #d8dff1;
$blue-light: #80d3f6;
$blue-medium: #003764;
$red: #b00600;

$text-h1: calc(1rem + 7.2vw);
$text-h2: calc(1rem + 5.1vw);
$text-h3: calc(1rem + 2.1vw);
$text-md: calc(0.7rem + 2.1vw);
$text-base: calc(0.5rem + 2.1vw);
$text-sm: calc(0.3rem + 2.1vw);

$body-text-shadow: 0 0.25em 0.25em rgba(0, 0, 0, 0.25);

@mixin font-regular {
  font-family: "96SansCondensed", sans-serif;
}

@mixin font-bold {
  font-family: "96SansBoldCondensed", sans-serif;
}

@mixin font-heavy {
  font-family: "96SansHeavy", sans-serif;
}
