canvas {
  display: none;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  margin-top: -1px;
}
#loadBackground {
  background-color: transparent;
}

.prompt-box-8w {
  color: black !important;
  background-color: white !important;
}
.button-primary-8w {
  // background-color: $brand-primary !important;
}

#requestingCameraPermissions {
  // background-color: $brand-primary !important;
}
