html {
  font-size: 16px;
}

html,
body {
  background-color: $blue-medium;
  width: 100%;
  height: 100%;
}

body {
  font-size: $text-base;
  line-height: 1.46;
  touch-action: pan-x pan-y;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.1;
}

button {
  background: none;
  border: none;
  outline: none;
}

h1 {
  // font-size: 2.75rem;
  font-size: $text-h1;
}
h2 {
  // font-size: 2.25rem;
  font-size: $text-h2;
}
h3 {
  font-size: $text-h3;
}
// input {
//   font-size: calc(1rem + 2.1vw);
// }

p {
  font-size: $text-base;
}

a {
  color: white;
}

.relative {
  position: relative;
}

.w-full {
  width: 100%;
}
