@import "./variables";
@import "./mixins";
@import "./animations";

#root {
  position: relative;
  z-index: 1;
}

#recorder {
  &.hidden {
    display: none;
  }
}

#ar-canvas {
  // z-index: -1;
  touch-action: none;
}

.screen-reader-only {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

button,
.button {
  background-color: $blue-light;
  border-radius: 50px;
  color: $white;
  display: inline-block;
  font-size: 1.1em;
  letter-spacing: 1px;
  line-height: 1;
  padding: 16px 30px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
  &.blue-light {
    background-color: $blue-light;
    color: $blue-medium;
    &:hover {
      background-color: $blue-medium;
      color: $white;
    }
  }
  &.blue-medium {
    background-color: $blue-medium;
    &:hover {
      background-color: $blue-light;
      color: $blue-medium;
    }
  }
}

.prompt-box-8w {
  * {
    @include font-regular;
    font-size: 20px !important;
    line-height: 1.1;
  }
  p {
    color: $blue-medium !important;
  }
  button {
    @include font-bold;
    background-color: #aaaaaa !important;
    border-radius: 50px !important;
    padding-bottom: 16px;
    padding-top: 16px;
    &.button-primary-8w {
      background-color: $blue-medium !important;
      color: $white;
      &:hover {
        background-color: $blue-medium !important;
        color: $white;
      }
    }
  }
}

#requestingCameraIcon {
  height: 4vh !important;
}

#requestingCameraPermissions {
  background-color: $blue-medium !important;
  @include font-regular;
  color: $white !important;
  font-size: 20px !important;
}

#cameraPermissionsErrorApple,
#cameraPermissionsErrorAndroid,
#microphonePermissionsErrorApple,
#microphonePermissionsErrorAndroid {
  display: none !important;
}

#recorder {
  display: none;
  &.enable {
    display: block;
  }
}
